import logo from '../assets/img/logo.png'
import {useState} from "react";

export default function Header(){

    const [isOpen, setIsOpen] = useState(false);


  return (
        <header className="header">
            <div className="container">
                <nav className="navigation" role="navigation">
                    <ul className="logo">
                        <img src={logo} aria-hidden="true"/>
                    </ul>
                    <ul className={isOpen ? "navigation-menue close" :"navigation-menue"}>
                        <li><a href="#search" onClick={()=>isOpen ? setIsOpen(!isOpen) : null}>Recherche</a></li>
                        <li><a href="#categorie" onClick={()=>isOpen ? setIsOpen(!isOpen) : null}>Catégories</a></li>
                        <li><a href="#newsletter" onClick={()=>isOpen ? setIsOpen(!isOpen) : null}>Newsletter</a></li>
                    </ul>
                    <button type="button" id="navigation-mobile" className={isOpen ? "close" :null} aria-label={isOpen ? "Fermer le menue" :"Ouvrir le menue"} aria-expanded={isOpen} onClick={()=>setIsOpen(!isOpen)}>
                        <span className="one"></span>
                        <span className="two"></span>
                        <span className="three"></span>
                    </button>
                </nav>
            </div>
        </header>
  );
}