import React from 'react'

const Pagination = ({ totalPages, handleClick, page }) => {
    const pages = [...Array(totalPages).keys()].map(num => num+1);
    return (
        <>
            {pages.length > 1 ?
                <div className="pagination">
                    { pages.map(num => (
                        <button
                            key={num}
                            onClick={() => handleClick(num)}
                            className={num === page ? "active" : ""}
                        >{num}</button>
                    )) }
                </div> : ''}
        </>
    )
}

export default Pagination