import facebook from '../assets/img/facebook.svg'
import instagram from '../assets/img/instagram.svg'
import pinterest from '../assets/img/pinterest.svg'
import twitter from '../assets/img/twitter.svg'
import logo from '../assets/img/logo.png'

export default function Footer(){
    return (
        <footer className="footer">
            <div className="container">
                <div className="custom-flex">
                    <div className="footer-container-information custom-flex_column">
                        <div className="logo-footer">
                            <img src={logo} alt="Logo bookR" aria-hidden="true"/>
                        </div>
                        <div className="copyright">
                            <p className="text">Copyright © 2022 BOOKR by Florian - Olivier - Théo</p>
                        </div>
                    </div>
                    <div className="footer-container-information custom-flex_column">
                        <p className="title-footer suivi">Suivez-nous</p>
                        <div className="rs">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.fr">
                                        <img src={facebook} aria-hidden="true"/>
                                        <span className="sr-only">
                                        Le site Facebook
                                    </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.fr">
                                        <img src={twitter} aria-hidden="true"/>
                                        <span className="sr-only">
                                        Le site Twitter
                                    </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.fr">
                                        <img src={instagram} aria-hidden="true"/>
                                        <span className="sr-only">
                                        Le site Instagram
                                    </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.pinterest.fr">
                                        <img src={pinterest} aria-hidden="true"/>
                                        <span className="sr-only">
                                        Le site Pinterest
                                    </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-container-about custom-flex_column">
                        <p className="title-footer">À propos</p>
                        <ul>
                            <li>
                                <a href="#" className="text">CGU / CGV</a>
                            </li>
                            <li>
                                <a href="#" className="text">Mentions légales</a>
                            </li>
                            <li>
                                <a href="#" className="text">Politique de confidentialité</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}