import {useState} from "react";

export default function BookCard(props){
    let tabAuthors = [];
    if(props.book["Auteur(s)"]){
        props.book["Auteur(s)"].forEach(function(author){
            props.authors.forEach(function(author2){
                if(author === author2.id){
                    tabAuthors.push(author2.name)
                }
            });
        });
    }
    const [show, setShow] = useState(false);
    function toggleModal(id){
        setShow(!show);setShow(!show)
    }

    return (
        <div className="card" data-cat={props.book.Topic.map((topic)=>" "+topic)}>
            <div className="card-header">
                {props.book.Cover ? <img src={props.book.Cover[0].url} aria-hidden="true" alt={props.book.Titre} /> : ''}
            </div>
            <div className="card-body">
                {props.book.Topic.map(
                    (topic, index) => <span className="tag tag-teal" key={index}>{topic}</span>
                )}
                <h3 className="text-subtitle">
                    {props.book.Titre}
                </h3>
                <p className="text">
                    {tabAuthors.map(()=>{
                        return tabAuthors.join(", ")
                    })}
                </p>
                <button className="contrast" data-target={"modal-example"+props.index} onClick={()=>toggleModal("modal-example"+props.index)}>
                    Voir plus d'informations
                </button>

                <dialog id={"modal-example"+props.index} className={show ? 'show' : ''}>
                    <article>
                        <a href="#close"
                           aria-label="Close"
                           className="close"
                           data-target={"modal-example"+props.index}
                           onClick={()=>setShow(!show)}>
                        </a>
                        <h3>{props.book.Titre}</h3>
                        {tabAuthors.length > 0 ?
                            <p className="text">
                                {tabAuthors.map(()=>{
                                    return tabAuthors.join(", ")
                                })}
                            </p> : ''}
                        {props.book.Topic ?
                            <p>
                                Catégorie(s) :
                                {props.book.Topic.map(
                                    (topic, index) => <span className="tag tag-teal" key={index}>{topic}</span>
                                )}
                            </p> : ''}
                        {props.book.Statut ?
                            <p>Statut :
                                {props.book.Statut.map(
                                    (status, index) => <span className="tag tag-purple" key={index}> {status}</span>
                                )}
                            </p> : ''}
                        {props.book.Note ? <p>Note : {props.book.Note}/5</p> : ''}
                        {props.book["Personal Notes"] ? <p>{props.book["Personal Notes"]}</p> : ''}
                        {props.book["Où le trouver"] ? <p>Disponible <a target="_blank" rel="noopener" href={props.book["Où le trouver"]}>ici</a></p> : ''}
                    </article>
                </dialog>
            </div>
        </div>
    );
}




