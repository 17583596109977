import Header from "../components/Header";
import Footer from "../components/Footer";
import books from "../assets/img/book.png";
import Airtable from "airtable";
import {useEffect, useState} from "react";
import BookList from "../components/BookList";
import Pagination from "../components/Pagination";
import '@picocss/pico/docs/js/modal.js';
var base = new Airtable({apiKey:'key2vKu7rG690riPb'}).base('appUFbV9h5M7I2gLu');


export default function Homepage(){
    const [livres, setLivres] = useState([]);
    const [livresFiltres, setLivresFiltres] = useState([]);
    const [auteurs, setAuteurs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filterCategories, setFilterCategories] = useState('all');
    const [termeRecherche, setTermeRecherche] = useState('');
    const [nbResultats, setNbResultats] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    function getBooksData() {
        let data = [];
        base('Books').select({
            maxRecords: 150,
            view: "Galerie – Tous les livres"
        }).firstPage(function (err, records) {
            if (err) {
                console.error(err);
                return;
            }
            records.forEach(function (record) {
                data.push(record.fields);
            });
            setLivres(data);
            setLivresFiltres(data);
            setNbResultats(data.length)
            setTotalPages(Math.ceil(data.length / 15));
            let topics = [];
            data.forEach((book)=>{
                book.Topic.forEach((topic)=>{
                    if(!topics.includes(topic)){
                        topics.push(topic);
                    }
                })
            })
            setCategories(topics);
        });
    }

    function getAllBooksData() {
        base('Books').select().all().then(records => {
            setLivres(records.map(record => record.fields));
            console.log(records.map(record => record.fields.Topic))
        }).catch(err => {
            console.error(err);
        });
    }

    function getAllAuthorsData() {
        base('Authors').select().all().then(records => {

            let authors = [];
            records.forEach(function (record) {
                authors.push({
                    id: record.id,
                    name : record.fields.Name,
                    books : record.fields.Books
                });
            });
            setAuteurs(authors);
        }).catch(err => {
            console.error(err);
        });
    }

    function getAuthorsData() {
        let data = [];
        base('Authors').select({
            maxRecords: 100,
            view: "Table des auteurs"
        }).firstPage(function (err, records) {
            if (err) {
                console.error(err);
                return;
            }
            records.forEach(function (record) {
                data.push(record.fields);
            });
            setAuteurs(data);
            console.log(data);
        });
    }

    function filterTab(text,cat) {
        console.log(text,cat)
        let tab = [];
        livres.forEach((book)=>{
            if(cat === 'all'){
                if(book.Titre.toLowerCase().includes(text.toLowerCase())){
                    tab.push(book);
                }
            }else{
                if(book.Topic.includes(cat) && book.Titre.toLowerCase().includes(text.toLowerCase())){
                    tab.push(book);
                }
            }
        });
        setLivresFiltres(tab);
        setNbResultats(tab.length);
        setTotalPages(Math.ceil(tab.length / 15));
        setPage(1)
        console.log(Math.ceil(tab.length / 15))
    }

    const handleClick = num => {
        setPage(num);
    }

    useEffect(()=>{
        getBooksData();
        getAllAuthorsData();
    },[]);



    return (
    <>
        <Header />
        <section className="landing">
            <div className="container">
                <div className="custom-flex">
                    <div className="custom-flex_column">
                        <h1 className="text-title">Bienvenue sur Bookr</h1>
                        <h2 className="text-subtitle">
                            Le site d'actualité des maisons d'édition du Groupe Bookr.
                        </h2>
                        <p className="text">
                            Livres, auteurs, ... Bookr vous fait découvrir les livres autrement.
                            Partageons ensemble le plaisir de lire !
                        </p>
                    </div>
                   <div className="custom-flex_column">
                       <img src={books} aria-hidden="true"/>
                   </div>
                </div>
            </div>
        </section>
        <section id="search" className="search">
            <div className="container">
                <div className="custom-flex">
                    <div className="custom-flex_column">
                        <h2 className="text-subtitle">Recherchez un livre</h2>
                        <p className="text">
                            Vous avez un livre à découvrir ?
                            Trouvez le livre qui vous correspond le plus.
                        </p>
                    </div>
                    <div className="custom-flex_column">
                        <select name="categorie" className="search-select" onChange={({target})=>{filterTab(termeRecherche,target.value);setFilterCategories(target.value)}}>
                            <option value="all">Toutes les catégories</option>
                            {categories.map((category, index)=>{
                                return <option key={index} value={category} data-cat={category}>{category}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="search_bar">
                    <input type="search" placeholder="Recherche..." onChange={({target})=>{filterTab(target.value,filterCategories);setTermeRecherche(target.value)}}/>
                    {nbResultats} livres trouvés
                </div>
                <div className="custom-flex" id="categorie">
                    <BookList books={livresFiltres} authors={auteurs} categories={categories} page={page}/>
                </div>
            </div>
            <div className="container">
                <Pagination totalPages={totalPages} handleClick={handleClick} page={page} />
            </div>
        </section>
        <section className="newsletter" id="newsletter">
            <div className="container">
                <div className="newsletter-text">
                    <h3 className="text-title">Soit notifié sur les nouvelles sorties !</h3>
                    <p className="text">Inscrivez-vous à la newsletter de BOOKR pour recevoir toutes les nouvelles sorties de la semaine.</p>
                </div>
                <div className="newsletter-form">
                    <input type="email" pattern=".+@globex\.com" placeholder="Entrer votre adresse email" required/>
                    <button>S'inscrire maintenant</button>
                </div>
            </div>
        </section>
        <Footer />
    </>
  );
}