"use strict";
/* eslint-enable @typescript-eslint/no-explicit-any */
function check(fn, error) {
    return function (value) {
        if (fn(value)) {
            return { pass: true };
        }
        else {
            return { pass: false, error: error };
        }
    };
}
check.isOneOf = function isOneOf(options) {
    return options.includes.bind(options);
};
check.isArrayOf = function (itemValidator) {
    return function (value) {
        return Array.isArray(value) && value.every(itemValidator);
    };
};
module.exports = check;
