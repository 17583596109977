import BookCard from "./Book-card";

export default function BookList(props){
    console.log(props.books)
    const startIndex = ( props.page - 1 ) * 15;
    const selectedUsers = props.books.slice(startIndex, startIndex + 15);
    return selectedUsers.map((book,index) => (
        <BookCard key={book.Titre} book={book} authors={props.authors} categories={props.categories} filterCategories={props.filterCategories} index={index}/>
    ))

    /*return (
        <>
        {props.books.map(book => (
            <BookCard key={book.Titre} book={book} authors={props.authors} categories={props.categories} filterCategories={props.filterCategories}/>
            ))}
        </>
    )*/
}