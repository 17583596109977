import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Airtable from "airtable";
import axios from "axios";

new Airtable({apiKey:'key2vKu7rG690riPb'}).base('appUFbV9h5M7I2gLu');
axios.defaults.baseURL =   'https://api.airtable.com/v0/appUFbV9h5M7I2gLu/map/';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers['Authorization'] = 'Bearer key2vKu7rG690riPb';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
